import React, { useEffect } from 'react'
import { Col, Container, Row } from 'reactstrap'
import Helmet from '../components/Helmet/Helmet'
import {useLocation, useParams} from 'react-router-dom'
import BookingForm from '../components/UI/BookingForm'
import PaymentMethod from '../components/UI/PaymentMethod'
import { useGetCar } from '../features/car.queries'
import CarouselSlider from "../components/UI/CarouselSlider";
import fuelTypeData from "../assets/data/fuelType";
import { useTranslation } from 'react-i18next'

const CarDetails = () => {
  const { slug } = useParams()
  const { t } = useTranslation("cars")
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const { data, isLoading } = useGetCar(slug)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [data])

  return (
    <Helmet title={data?.name}>
      <section>
        <Container>
          <Row>
          <Col lg="6">
          <CarouselSlider items={data?.images?.map(img => {
            return {
              src:  process.env.REACT_APP_SERVER_BASE_API + "/" +img.path,
              altText: img.originalName
            }
          }) || []} />
        </Col>

            <Col lg="6">
              <div className="car__info">
                <h2 className="section__title">{data?.name}</h2>

                <div className=" d-flex align-items-center gap-5 mb-4 mt-3">
                  <h6 className="rent__price fw-bold fs-4">
                    {data?.pricePerDay}.00 MAD / {t("day")}
                  </h6>
                </div>

                <p className="section__description">{data?.description}</p>

                <div
                  className=" d-flex align-items-center mt-3"
                  style={{ columnGap: '1rem',flexWrap:'wrap' }}
                >
                  <span className=" d-flex align-items-center gap-1 section__description">
                    <i
                      className="ri-building-2-line"
                      style={{ color: 'var(--main-color)' }}
                    ></i>{' '}
                    {data?.marque}
                  </span>

                  <span className=" d-flex align-items-center gap-1 section__description">
                    <i
                      class="ri-roadster-line"
                      style={{ color: 'var(--main-color)' }}
                    ></i>{' '}
                    {data?.model}
                  </span>

                  <span className=" d-flex align-items-center gap-1 section__description">
                    <i
                      class="ri-settings-2-line"
                      style={{ color: 'var(--main-color)' }}
                    ></i>{' '}
                    {t(data?.gearbox)}
                  </span>

                  <span className=" d-flex align-items-center gap-1 section__description">
                    <i
                      class="ri-timer-flash-line"
                      style={{ color: 'var(--main-color)' }}
                    ></i>{' '}
                    {/* {fuelTypeData.find(f => f.value === data?.fuelType)?.label} */}
                    {t(data?.fuelType)}
                  </span>
                </div>

                <div
                  className=" d-flex align-items-center mt-4"
                  style={{ columnGap: '2.8rem' }}
                >
                  <span className=" d-flex align-items-center gap-1 section__description">
                    <i
                      class="ri-wheelchair-line"
                      style={{ color: 'var(--main-color)' }}
                    ></i>{' '}
                    {data?.doors}
                  </span>

                  <span className=" d-flex align-items-center gap-1 section__description">
                    <i
                      className="ri-map-pin-line"
                      style={{ color: 'var(--main-color)' }}
                    ></i>{' '}
                    {data?.equipment}
                  </span>
                </div>
              </div>
            </Col>

            <Col lg="12" className="mt-5">
              <div className="booking-info mt-5">
                <h5 className="mb-4 fw-bold ">{t("bookingInformation")}</h5>
                <BookingForm
                    carId={slug}
                    return_Date={params.get("returnDate")}
                    pickUp_Date={params.get("pickUpDate")}
                    return_Place={params.get("returnPlace")}
                    pickUp_Place={params.get("pickUpPlace")}
                />
              </div>
            </Col>


          </Row>
        </Container>
      </section>
    </Helmet>
  )
}

export default CarDetails
