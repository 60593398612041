import React from "react";

import HeroSlider from "../components/UI/HeroSlider";
import FindCarForm from "../components/UI/FindCarForm";
import ServicesList from "../components/UI/ServicesList";
import CarInfo from "../components/UI/CarInfo";

import { Container, Row, Col } from "reactstrap";
import { Box, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next"; 
import FilterCarsFormHome from "../components/UI/FilterCarsFormHome";

// import AboutSection from "../components/UI/AboutSection";
//
// import carData from "../assets/data/carData";
// import CarItem from "../components/UI/CarItem";
// import BecomeDriverSection from "../components/UI/BecomeDriverSection";
// import Testimonial from "../components/UI/Testimonial";

// import BlogList from "../components/UI/BlogList";

const Home = () => {

  const navigate = useNavigate()
  const { t } = useTranslation("welcome");

  return (
    <div class="ay">
       {/* ========== HEADER ============ */}
    <HeroSlider />

      <Box 
        sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: "translate(-50%, -50%)",
            color: "white",
            textAlign: 'center'
          }}
        >
        <Typography variant="h1" sx={{
            "@media screen and (max-width: 600px)": {
              fontSize: "400%"
          }
        }}>{t("welcomeMsg")}</Typography>
        {/* <Button variant="outlined" > Réserver un véhicule </Button> */}
        {/* <Button
          onClick={() => navigate("/cars")}
          variant="contained"
          disableElevation
          sx={{
            // backgroundColor: 'black',
            backgroundColor: 'var(--main-color)',
            marginTop: '20px',
            transform: "scale(1.2)",
            // flex: 1,
            "&:hover": {
              backgroundColor: 'black',
              // backgroundColor: 'var(--main-color)',
            }
          }}
        >
          {t("bookCar")}
        </Button> */}

        <FilterCarsFormHome />
      </Box>

        <section>
        <Container>
          <Row>
            <Col lg="12" className="mb-5 text-center">
              {/* <h6 className="section__subtitle">{t("seeOur")}</h6> */}
              <h2 >{t("popularServices")}</h2>
            </Col>

            <ServicesList />
          </Row>
        </Container>
      </section>



  <Container>
    <Row>
      <Col lg="12" className="mb-5 text-center">
        {/* <h6 className="section__subtitle">{t("seeOur")}</h6> */}
        <h2>{t("sectionCars")}</h2>
      </Col>
      <CarInfo />
    </Row>
  </Container>

</div>




  );
};

export default Home;
