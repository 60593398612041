import React from "react";
import {Col} from "reactstrap";
import {Link} from "react-router-dom";
import "../../styles/car-item.css";
import moment from "moment";
import { useTranslation } from "react-i18next";


const CarItem = (props) => {

    const {images, dateOfPurchase, pricePerDay, gearbox, fuelType, _id, name, availableFrom, availableTo, availableAfter} = props.item;
    const {return_Date, pickUp_Date, pickUp_Place, return_Place} = props;
    const { t } = useTranslation("cars")

    return (

        <Col lg={4} md={4} sm={6} className="mb-3">

            <div className="car__item">
                <Link to={`/cars/${_id}?${pickUp_Date ? "&pickUpDate=" +pickUp_Date : ""}${return_Date ? "&returnDate=" + return_Date : ""}${pickUp_Place ? "&pickUpPlace=" + pickUp_Place : ""}${return_Place ? "&returnPlace=" + return_Place : ""}`}>
                    <div className="car__img">
                        <img
                            src={images.length > 0 ? process.env.REACT_APP_SERVER_BASE_API + "/" + images[0]?.path : null}
                            alt="" className="ImageCard" width="100%"  style={{"object-fit": "scale-down"}} />

                    </div>
                </Link>

                <div className="car__item-content mt-4">
                <h6 className="section__title text-center">
                    {name}
                    <span style={{color: "rgba(0, 0, 0, 0.7)", fontSize: 16}}>
                        {" " + t("orEquivalent")}
                    </span>
                </h6>

                {/* <h6 className="section__title text-center"> */}
                    {/* disponible après: {moment(availableAfter).format("DD/MM/YYYY HH:mm")} */}
                    {/* {
                        availableAfter 
                        ? <span>disponible après: {moment(availableAfter).format("DD/MM/YYYY HH:mm")}</span>
                        : (availableFrom && availableTo) && (
                            <>

                            </>
                        )
                    } */}
                {/* </h6> */}

            </div>
                <div className="car__item-info d-flex align-items-center justify-content-between mt-3 flex-wrap">
                <span className=" d-flex align-items-center gap-1  ">
                  <i class="ri-car-line"></i> {moment(dateOfPurchase).format("YYYY")}
                </span>

                    <span className=" d-flex align-items-center gap-1 ">
                  <i class="ri-settings-2-line"></i> {t(gearbox)}
                </span>
                    <span className=" d-flex align-items-center gap-1 ">
                  <i class="ri-timer-flash-line"></i> {t(fuelType)}
                </span>

                    <span className="d-flex align-items-center gap-1  ">

                {pricePerDay} MAD
              </span>
                </div>

            </div>


        </Col>
    );
};

export default CarItem;
