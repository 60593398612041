import { makeStyles } from '@mui/styles'
import 'react-phone-input-2/lib/material.css'
import PhoneInput from 'react-phone-input-2'
import { Stack } from '@mui/material'

const useStyles = makeStyles({
  root: (err) => ({
    '& .special-label': {
      top: -9,
      left: 10,
      // fontSize: '0.7rem',
      borderRadius: 5,
      color: `${err ? '#D32F2F' : 'rgba(0, 0, 0, 0.6)'} !important`,
      maxWidth: '80%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },

    '& input': {
      height: 40,
      width: '100% !important',
      borderColor: `${err ? '#D32F2F' : ''} !important`,
      fontSize: '14px !important',
    },

    '& .form-control ': {
      height: 40,
      width: '100% !important',
      borderColor: `${err ? '#D32F2F' : ''} !important`,
      fontSize: '14px !important',
      backgroundColor: 'blue',
    },

    '& .aa ': {
      height: 40,
      width: '100% !important',
      borderColor: `${err ? '#D32F2F' : ''} !important`,
      fontSize: '14px !important',
      backgroundColor: 'blue',
    },

    '& input:focus': {
      borderColor: `${err ? '#D32F2F' : '#0d6efd'} !important`,
      boxShadow: `0 0 0 1px ${err ? '#D32F2F' : '#0d6efd'} !important`,
    },

    '&:has(input:focus) .special-label': {
      color: `${err ? '#D32F2F' : '#0d6efd'} !important`,
    },
  }),
})

export default function PhoneNumber(props) {
  const classes = useStyles(false)

  return (
    <Stack sx={{ width: '100%' }}>
      <PhoneInput
        {...props}
        inputStyle={{ width: '100%',  height: 50, }} 
      />
    </Stack>
  )
}
