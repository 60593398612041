import React, {useEffect, useState} from "react";
import masterCard from "../../assets/all-images/master-card.jpg";
import moment from "moment";
import Spinner from 'react-bootstrap/Spinner';
import axiosInstance from "../../lib/axios";
import {useGetLocations} from "../../features/locations.queries";
import ComboBox from "../ComboBox";
import { Box, Button, Checkbox, FormControlLabel, Grid, TextField, Typography, styled } from "@mui/material";
import PhoneNumber from "../PhoneNumber";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker, DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import GeoPlaceModal from "../GeoPlaceModal"; 

const StyledDatePicker = styled(DatePicker)(({theme}) => ({
    width : "100%",
    height: 40,

    '.MuiFormLabel-root': {
      color : "rgba(0, 0, 0, 0.3) !important",
    },

    ".css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      borderColor : "rgba(0, 0, 0, 0.3) !important",
    },
      
    ".MuiInputBase-root": {
        borderColor : "rgba(0, 0, 0, 0.3) !important",
        height: 40
      },
      
    ".css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root": {
        borderColor : "rgba(0, 0, 0, 0.3) !important",
        height: 40
      },

    "& label.MuiFormLabel-root" : {
        // color: 'red !important',
        backgroundColor: 'white',
        paddingRight: 5

    },

    "&:hover" : {
      '.MuiFormLabel-root': {
        color : "rgba(0, 0, 0, 0.8) !important",
      },
      ".css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
        borderColor : "rgba(0, 0, 0, 0.8) !important",
      },
    },

}))

const StyledDateTimePicker = styled(DateTimePicker)(({ theme }) => ({
    width: "100%",
    height: 40,
  
    // Targeting the specific MuiInputBase and MuiOutlinedInput classes
    ".MuiOutlinedInput-root": {
      borderColor: "rgba(0, 0, 0, 0.3) !important",
      height: 40,
    },
  
    // Targeting the label for the input
    ".MuiFormLabel-root": {
      color: "rgba(0, 0, 0, 0.3) !important",
    },
  
    // Styling the error state
    ".MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(0, 0, 0, 0.3) !important",
    },
  
    // Styling the label itself
    "& label.MuiFormLabel-root": {
      backgroundColor: "white",
      paddingRight: 5,
    },
  
    // Hover effect
    "&:hover": {
      ".MuiFormLabel-root": {
        color: "rgba(0, 0, 0, 0.8) !important",
      },
      ".MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
        borderColor: "rgba(0, 0, 0, 0.8) !important",
      },
    },
}));
  


const BookingForm = ({return_Date, pickUp_Date, carId, return_Place, pickUp_Place}) => {

    const navigate = useNavigate()
    const { t } = useTranslation("cars")

    const [civility, setCivility] = useState(null);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [cin, setCin] = useState("");
    const [drivingLicense, setDrivingLicense] = useState("");
    const [birthdate, setBirthdate] = useState(null);
    const [html, setHTML] = useState({__html: ""});

    const [historyPlaces, setHistoryPlaces] = useState([])
    const [currentModalTarget, setCurrentModalTarget] = useState("")

    const [loading, setLoading] = useState(false);
    const [onlinePayment, setOnlinePayment] = useState(false)

    const [pickUpDate, setPickUpDate] = useState(pickUp_Date ? moment(parseInt(pickUp_Date)).format("YYYY-MM-DDTHH:mm"): moment().format("YYYY-MM-DDT14:00"));
    const [returnDate, setReturnDate] = useState(return_Date ? moment(parseInt(return_Date)).format("YYYY-MM-DDTHH:mm") : moment().add(3, "days").format("YYYY-MM-DDT14:00"));
    const [pickUpPlace, setPickUpPlace] = useState(pickUp_Place);
    const [returnPlace, setReturnPlace] = useState(return_Place ||"sameAsPickup");

    const states = {
        "returnPlace": {
            state: returnPlace,
            setState: setReturnPlace,
        },

        "pickUpPlace": {
            state: pickUpPlace,
            setState: setPickUpPlace,
        },
    }

    useEffect(
        () => {

            if(pickUpPlace?.value === "new") {
                setCurrentModalTarget("pickUpPlace")
            }
            
            if(returnPlace?.value === "new") {
                setCurrentModalTarget("returnPlace")
            }

        }, [pickUpPlace, returnPlace]
    )


    const {data = [], isLoading} = useGetLocations()

    const submit = async (event) => {
        event.preventDefault();
        setLoading(true);

        try {

            if (
                !civility ||
                !firstName ||
                !lastName ||
                !email ||
                !phone ||
                !cin ||
                !drivingLicense ||
                !birthdate ||
                !pickUpDate ||
                !returnDate ||
                !pickUpPlace ||
                !returnPlace
            ) {
                Swal.fire({
                    position: "center",
                    icon: "error",
                    title: t("allFieldsRequiredError"),
                    showConfirmButton: false,
                    timer: 1500,
                });
                setLoading(false);
                return;
            } else if (birthdate && moment().diff(moment(birthdate), 'years') < 18) {
                Swal.fire({
                    position: "center",
                    icon: "error",
                    title: t("ageYoungError"),
                    showConfirmButton: false,
                    timer: 1500,
                });
                setLoading(false);
                return;
            }
            
            const response = await axiosInstance.post("/pre-book-car", {
                onlinePayment,
                civility: civility?.value,
                firstName,
                lastName,
                email,
                phone,
                cin,
                drivingLicense,
                birthdate: moment(birthdate).toISOString(),
                pickUpAddress: pickUpPlace?.value,
                returnAddress: returnPlace?.value,
                pickUpDate: moment(pickUpDate).format("x"),
                returnDate: moment(returnDate).format("x"),
                carId
            });

            if(onlinePayment) {
                const backendHtmlString = await response.data.form
    
                console.log(backendHtmlString)
                setHTML({__html: backendHtmlString});
    
                setTimeout(() => {
                    document.getElementById('formPayzoneBookCar').submit();
                }, 500)
            } else {

                const book = await axiosInstance.post(
                    "/book-car-notification",
                    {
                        status: "CHARGED",
                        id: response?.data?.bookingTemporary,
                        bookedFrom: "client",
                        paymentType: "on-site",
                    }
                );

                Swal.fire({
                    position: "center",
                    icon: "success",
                    title: t("bookSuccess"),
                    showConfirmButton: false,
                    timer: 1500,
                });
                // navigate("/cars")
                navigate("/")
            }


            setLoading(false);

        } catch (e) {
            setLoading(false);
        }


    };


    return (
        <>
            <div dangerouslySetInnerHTML={html}/>
            <Box
                component="form"
                autoComplete="off"
                onSubmit={submit}
                // noValidate
            >

                <Grid container spacing={2}>
                    
                    <Grid item xs={12} sm={6} md={4}>
                        <ComboBox 
                            options={
                                [ "M", "Mme", "Mlle" ]
                                .map(c => ({value: c, label: t(c)}))
                            }
                            label={t("civility")}
                            onSelect={(data) => setCivility(data?.value)}
                            required
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            size="small"
                            label={t("firstName")}
                            variant={"outlined"}
                            fullWidth
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            required
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            size="small"
                            label={t("lastName")}
                            variant={"outlined"}
                            fullWidth
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            required
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            size="small"
                            label={t("email")}
                            variant={"outlined"}
                            fullWidth
                            value={email}
                            type="email"
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    
                    <Grid item xs={12} sm={6} md={4}>
                        <PhoneNumber
                            specialLabel={t("phone")}
                            fullWidth
                            country={"ma"}
                            value={phone}
                            onChange={setPhone}
                            inputProps={{required: true}}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            size="small"
                            label={t("idCard")}
                            variant={"outlined"}
                            fullWidth
                            value={cin}
                            onChange={(e) => setCin(e.target.value)}
                            required
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            size="small"
                            label={t("driverLisence")}
                            variant={"outlined"}
                            fullWidth
                            value={drivingLicense}
                            onChange={(e) => setDrivingLicense(e.target.value)}
                            required
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <StyledDatePicker
                                label={t("birthdate")}
                                // disableFuture
                                maxDate=  {moment().subtract(18, 'years')} 
                                format="DD/MM/YYYY"
                                onChange={(newValue) => setBirthdate(moment(newValue).format("YYYY-MM-DD"))}
                                value={moment(birthdate)}
                                slotProps={{ textField: { required: true }}}
                            />
                        </LocalizationProvider>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <StyledDateTimePicker
                                label={t("departDate")}
                                // readOnly
                                disablePast 
                                minutesStep={30}
                                ampm={false} 
                                skipDisabled
                                format="DD/MM/YYYY HH:mm"
                                onChange={(newValue) => setPickUpDate(moment(newValue).format("YYYY-MM-DDTHH:mm"))}
                                value={moment(pickUpDate)}
                            />
                        </LocalizationProvider>
                    </Grid>
                    
                    <Grid item xs={12} sm={6} md={4}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <StyledDateTimePicker
                                label={t("returnDate")}
                                // readOnly
                                disablePast 
                                minutesStep={30}
                                ampm={false}
                                skipDisabled
                                format="DD/MM/YYYY HH:mm"
                                onChange={(newValue) => setReturnDate(moment(newValue).format("YYYY-MM-DDTHH:mm"))}
                                value={moment(returnDate)}
                            />
                        </LocalizationProvider>
                    </Grid>
                    
                    <Grid item xs={12} sm={6} md={4}>
                        <ComboBox
                            key={`pickUpPlace-${currentModalTarget === "pickUpPlace"}`}
                            label={t("departPlace")}
                            options={
                                [
                                    // {value: "centerPoint", label: t("centerPoint")},
                                    ...(
                                        // historyPlaces?.map(hp => ({value: hp.place_id, label: hp.formatted_address}))
                                        data?.map(i => ({value: i._id, label: i.name}))
                                        || []
                                    ),
                                    // {value: "new", label: t("newPlace")},
                                ]
                            }
                            onSelect={setPickUpPlace}
                            defaultValue={pickUpPlace}
                            required
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                        <ComboBox
                            key={`returnPlace-${currentModalTarget === "returnPlace"}`}
                            label={t("returnPlace")}
                            options={
                                [
                                    // {value: "centerPoint", label: t("centerPoint")},
                                    // {value: "sameAsPickup", label: t("sameAsPickup")},
                                    ...(
                                        // historyPlaces?.map(hp => ({value: hp.place_id, label: hp.formatted_address}))
                                        data?.map(i => ({value: i._id, label: i.name}))
                                        || []
                                    ),
                                    // {value: "new", label: t("newPlace")},
                                ]
                            }
                            onSelect={setReturnPlace}
                            defaultValue={returnPlace}
                            required
                        />
                    </Grid>

                    <Grid item xs={8}>
                        {/* <FormControlLabel
                            control={<Checkbox defaultChecked={onlinePayment} onChange={()=>setOnlinePayment(prev => !prev)}/>} 
                            label={t("onlinePaiment")}
                        /> */}
                    </Grid>

                    <Grid item xs={4}>
                        <div className="payment text-end">
                            <Button
                                disabled={loading}
                                type="button"
                                variant="outlined"
                                disableElevation
                                onClick={() => navigate("/cars")}
                                sx={{
                                    textTransform: 'capitalize',
                                    mr: 4,

                                    borderColor: "black",
                                    color: "black",

                                    marginTop: '20px',
                                    transform: "scale(1.2)",
                                    "&:hover": {
                                        borderColor: "var(--main-color)",
                                        color: "var(--main-color)",
                                    }
                                }}
                            >
                                {t("cancel")}
                            </Button>

                            <Button
                                disabled={loading}
                                type="submit"
                                variant="contained"
                                disableElevation
                                sx={{
                                    textTransform: 'capitalize',
                                    backgroundColor: 'var(--main-color)',
                                    marginTop: '20px',
                                    transform: "scale(1.2)",
                                    "&:hover": {backgroundColor: 'black'}
                                }}
                            >
                                {
                                    !loading 
                                        ? t("book")
                                        : ( 
                                            <>
                                                <Spinner animation="border" role="status" size="sm" />
                                                <span style={{marginLeft: 5}}>{t("loading")}...</span>
                                            </>
                                        )
                                }
                            </Button>
                        </div>
                    </Grid>
                </Grid>

                {/* <GeoPlaceModal
                    open={!!currentModalTarget}
                    onClose={() => {
                        setTimeout(
                            () => {

                                if (states[currentModalTarget].state?.value === "new") {
                                    states[currentModalTarget].setState(null)
                                }

                                setCurrentModalTarget("")
                            },
                            400
                        )
                    }}
                    setAddress={(address) => {
                        if(address) {
                            setHistoryPlaces(prev => [...prev, address])
                            states[currentModalTarget].setState({
                                value: address.place_id,
                                label: address.formatted_address
                            })
                        } else {
                            states[currentModalTarget].setState("aph")
                        }
                    }}
                /> */}

                {/* <div className="payment mt-3 d-flex align-items-center justify-content-between gap-2">
                    <img src={masterCard} alt=""  style={{"object-fit": "scale-down"}}/>
                </div> */}

            </Box>
        </>

    );
};

export default BookingForm;
